<template>
  <div class="selbsterfahrung">
    <page-title slug="selbsterfahrung" alt="Selbsterfahrung">Selbsterfahrung</page-title>
    <v-page>
      <article class="text hyphenate" v-html="selbsterfahrung.selbsterfahrung"></article>

      <div class="content-img">
        <responsive-image
          :urls="{
            webp: [
              { size: 640, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_640.webp') },
              { size: 960, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_960.webp') },
              {
                size: 1280,
                url: require('@/assets/img/selbsterfahrung/selbsterfahrung_1280.webp'),
              },
              {
                size: 1920,
                url: require('@/assets/img/selbsterfahrung/selbsterfahrung_1920.webp'),
              },
            ],
            jpg: [
              { size: 640, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_640.jpg') },
              { size: 960, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_960.jpg') },
              { size: 1280, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_1280.jpg') },
              { size: 1920, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_1920.jpg') },
            ],
          }"
          sizes="(min-width: 1280px) min(960px, min(calc(100vw - 128px), 1280px)), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw"
          alt="Selbsterfahrung"
        ></responsive-image>
      </div>
    </v-page>
  </div>
</template>

<script>
import axios from 'axios'

import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'Selbsterfahrung',
  components: {
    PageTitle,
    VPage,
    ResponsiveImage,
  },
  data() {
    return {
      selbsterfahrung: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get('/selbsterfahrung')
    next(vm => (vm.selbsterfahrung = res.data.data))
  },
}
</script>
