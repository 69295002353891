var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selbsterfahrung"},[_c('page-title',{attrs:{"slug":"selbsterfahrung","alt":"Selbsterfahrung"}},[_vm._v("Selbsterfahrung")]),_c('v-page',[_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.selbsterfahrung.selbsterfahrung)}}),_c('div',{staticClass:"content-img"},[_c('responsive-image',{attrs:{"urls":{
          webp: [
            { size: 640, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_640.webp') },
            { size: 960, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_960.webp') },
            {
              size: 1280,
              url: require('@/assets/img/selbsterfahrung/selbsterfahrung_1280.webp'),
            },
            {
              size: 1920,
              url: require('@/assets/img/selbsterfahrung/selbsterfahrung_1920.webp'),
            } ],
          jpg: [
            { size: 640, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_640.jpg') },
            { size: 960, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_960.jpg') },
            { size: 1280, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_1280.jpg') },
            { size: 1920, url: require('@/assets/img/selbsterfahrung/selbsterfahrung_1920.jpg') } ],
        },"sizes":"(min-width: 1280px) min(960px, min(calc(100vw - 128px), 1280px)), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw","alt":"Selbsterfahrung"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }